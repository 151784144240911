.pagination a {
  @apply flex items-center border border-l-0 px-3 text-sm leading-loose opacity-75;
}

.pagination a.active {
  @apply font-semibold text-blue-600 !important;
}

.pagination a:first-child {
  @apply rounded-l-md border-l;
}

.pagination a:last-child {
  @apply rounded-r-md;
}
