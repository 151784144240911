@import url("../../node_modules/tom-select/dist/css/tom-select.css");

.error-label ~ .ts-wrapper > .ts-control {
  @apply border-red-500;
}

.error-label ~ .ts-wrapper > .ts-control > .item > .remove {
  @apply border-red-500 text-red-500;
}

.success-label ~ .ts-wrapper > .ts-control {
  @apply border-green-500;
}

.success-label ~ .ts-wrapper > .ts-control > .item > .remove {
  @apply border-green-500 text-green-500;
}

.ts-wrapper.multi > .ts-control > .item {
  @apply rounded-full bg-blue-500 p-2 text-white;
}

.ts-wrapper.single > .ts-control > .item {
  @apply flex w-full;
}

.ts-wrapper.single > .ts-control > .item > a {
  @apply ml-auto;
}

.ts-dropdown > .dropdown-input-wrap > .dropdown-input {
  @apply focus:border-blue-500 focus:ring-blue-500;
}

.ts-dropdown .optgroup-header {
  @apply text-sm font-bold;
}

.ts-wrapper > .ts-control {
  @apply rounded-lg bg-gray-50 p-2.5;

  background-image: url("/images/select/chevron_down.svg");
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  background-position: right 0.75rem center;
}

.ts-wrapper > .ts-control:has(.item) {
  background: none;
}

[size="default"] > .ts-wrapper > .ts-control {
  @apply text-sm;
}

.ts-wrapper.focus > .ts-control {
  @apply border-2 border-blue-500 ring-blue-500 !important;
}

.error-label ~ .ts-wrapper.focus > .ts-control {
  @apply border-red-500 ring-red-500 !important;
}

.success-label ~ .ts-wrapper.focus > .ts-control {
  @apply border-green-500 ring-green-500 !important;
}

.ts-wrapper.focus > .dropdown-input-wrap {
  @apply border-blue-500 !important;
}

/* Sidebar's client select styles */
#sidebar .ts-control {
  @apply border-0 p-2 text-base font-normal text-gray-500;
}

#sidebar.lg\:w-64 .ts-control {
  @apply border border-gray-300 bg-gray-50;
}
