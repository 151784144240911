@import url("tailwindcss/base");
@import url("tailwindcss/components");
@import url("tailwindcss/utilities");
@import url("./shared/select");
@import url("pagination");

@keyframes spin {
  100% {
    transform: rotate(0deg);
  }
}

.accordion summary {
  display: flex;
}

.accordion summary::after {
  border-radius: 5px;
  content: "^";
  float: left;
  font-size: 24px;
  font-weight: bold;
  margin: -7px 12px 0 0;
  padding: 0;
  text-align: center;
  width: 10px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transform: rotate(180deg);
}

.accordion [open] summary::after {
  animation: spin 0.3s forwards;
}

.accordion [open] summary {
  border-bottom: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.public-mobile {
  @apply h-auto max-w-full;

  width: 53%;
}

.public-mobile.public-mobile-front {
  object-position: 0 2.8rem;
}

.public-mobile.public-mobile-back {
  object-position: 0 4.5rem;
}

.background-header {
  background: linear-gradient(
    180deg,
    rgb(249 250 251) 50%,
    rgb(255 255 255) 50%
  );
}

.ts-control {
  min-height: 2.5rem;
}

@screen md {
  .public-mobile.public-mobile-front {
    object-position: 0 5.6rem;
  }

  .public-mobile.public-mobile-back {
    object-position: 0 9rem;
  }
}
